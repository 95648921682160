export default {
  base: {
    remove: "Remove",
    cancel: "Cancel",
    confirm: "Confirm",
    inert: "Insert",
    width: "Width",
    height: "Height",
  },
  controls: {
    clear: "Clear",
    undo: "Undo",
    redo: "Redo",
    fontSize: "Font Size",
    color: "Color",
    textColor: "Text",
    tempColors: "Temp Colors",
    backgroundColor: "Background",
    bold: "Bold",
    lineHeight: "Line Height",
    letterSpacing: "Letter Spacing",
    textIndent: "Text Indent",
    increaseIndent: "Increase Indent",
    decreaseIndent: "Decrease Indent",
    italic: "Italic",
    underline: "Underline",
    strikeThrough: "Strike Through",
    fontFamily: "Font Family",
    textAlign: "Text Alignment",
    alignLeft: "Left Alignment",
    alignCenter: "Center Alignment",
    alignRight: "Right Alignment",
    alignJustify: "Justify Alignment",
    floatLeft: "Left Float",
    floatRight: "Right Float",
    superScript: "Super Script",
    subScript: "Sub Script",
    removeStyles: "Remove Styles",
    headings: "Headings",
    header: "Header",
    normal: "Normal",
    orderedList: "Ordered List",
    unorderedList: "Unordered List",
    blockQuote: "Quote",
    code: "Code",
    link: "Link",
    unlink: "Unlink",
    hr: "Horizontal Line",
    media: "Media",
    mediaLibirary: "Media Library",
    emoji: "Emoji",
    fullscreen: "Fullscreen",
    exitFullscreen: "Exit Fullscreen",
  },
  linkEditor: {
    textInputPlaceHolder: "Input link text",
    linkInputPlaceHolder: "Input link URL",
    inputWithEnterPlaceHolder: "Input link URL and press Enter",
    inputHeadingWithEnterPlaceHolder: "Input content",
    openInNewWindow: "Open in new window",
    removeLink: "Remove Link",
  },
  audioPlayer: {
    title: "Play Audio",
  },
  videoPlayer: {
    title: "Play Video",
    embedTitle: "Embed Media",
  },
  media: {
    image: "Image",
    video: "Video",
    audio: "Audio",
    embed: "Embed",
  },
};
